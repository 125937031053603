import { makeAutoObservable, observable, runInAction } from 'mobx';
import {
  Announcement,
  ListAnnouncementsRequest,
  GetAnnouncementRequest,
  CreateAnnouncementRequest,
  UpdateAnnouncementRequest,
  DeleteAnnouncementRequest,
} from 'protos/pb/orby_internal/orby_internal_service';
import { RootStore } from './store';
import { announcementService } from '../services/AnnouncementService';

class AnnouncementStore {
  rootStore: RootStore;
  @observable announcements: Announcement[] = [];
  @observable totalSize?: number = 0;
  @observable loadingAnnouncements = false;
  @observable listAnnouncementsError?: Error;

  @observable createdAnnouncement?: Announcement;
  @observable updatedAnnouncement?: Announcement;

  @observable processingAnnouncement = false;
  @observable processAnnouncementError?: Error;

  @observable selectedAnnouncement?: Announcement;
  @observable loadingAnnouncement = false;
  @observable loadAnnouncementError?: Error;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  listAnnouncements = async (
    req: ListAnnouncementsRequest,
    refresh = false,
  ) => {
    runInAction(() => {
      this.loadingAnnouncements = true;
      this.listAnnouncementsError = undefined;
    });
    try {
      const { response, error } =
        await announcementService.getAnnouncements(req);
      runInAction(() => {
        if (response) {
          if (refresh) {
            this.announcements = response.announcements ?? [];
          } else {
            this.announcements.push(...(response.announcements ?? []));
          }
          this.totalSize = response.totalSize;
        } else {
          this.listAnnouncementsError = error;
        }
        this.loadingAnnouncements = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingAnnouncements = false;
        this.listAnnouncementsError = error as Error;
      });
    }
  };

  createAnnouncement = async (req: CreateAnnouncementRequest) => {
    this.processingAnnouncement = true;
    try {
      const { response, error } =
        await announcementService.createAnnouncement(req);
      runInAction(() => {
        if (response) {
          this.createdAnnouncement = response.announcement;
        } else {
          this.processAnnouncementError = error;
        }
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  updateAnnouncement = async (req: UpdateAnnouncementRequest) => {
    this.processingAnnouncement = true;
    try {
      const { response, error } =
        await announcementService.updateAnnouncement(req);
      runInAction(() => {
        if (response) {
          this.updatedAnnouncement = response.announcement;
        } else {
          this.processAnnouncementError = error;
        }
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  deleteAnnouncement = async (req: DeleteAnnouncementRequest) => {
    this.processingAnnouncement = true;
    try {
      await announcementService.deleteAnnouncement(req);
      runInAction(() => {
        this.announcements = this.announcements.filter(
          (ff) => ff.id !== req.id,
        );
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  getAnnouncement = async (req: GetAnnouncementRequest) => {
    this.loadingAnnouncement = true;
    try {
      const { response, error } =
        await announcementService.getAnnouncement(req);
      runInAction(() => {
        if (response) {
          this.selectedAnnouncement = response.announcement;
        } else {
          this.loadAnnouncementError = error;
        }
        this.loadingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingAnnouncement = false;
        this.loadAnnouncementError = error as Error;
      });
    }
  };

  setSelectedAnnouncement = (announcement?: Announcement) => {
    runInAction(() => {
      this.selectedAnnouncement = announcement;
    });
  };

  resetAnnouncementDetail = () => {
    runInAction(() => {
      this.selectedAnnouncement = undefined;
      this.loadAnnouncementError = undefined;
      this.loadingAnnouncement = false;
    });
  };

  clearErrors = () => {
    runInAction(() => {
      this.listAnnouncementsError = undefined;
      this.loadAnnouncementError = undefined;
      this.processAnnouncementError = undefined;
    });
  };
}

export default AnnouncementStore;
