import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  AnnouncementType,
  announcementTypeToJSON,
} from 'protos/common/announcement';
import {
  Announcement,
  ListAnnouncementsRequest,
} from 'protos/pb/orby_internal/orby_internal_service';
import CustomPagination from '../../components/core/CustomPagination';
import CustomTableCell from '../../components/core/CustomTableCell';
import CustomTableLabel from '../../components/core/CustomTableLabel';
import StyledTableRow from '../../components/core/StyledTableRow';
import CustomTypography, {
  TypographyType,
} from '../../components/core/CustomTypography';
import { useStore } from '../../hooks/useStore';
import { toastService } from '../../services/ToastService';
import { ACCENT_COLOR_1, ELLIPSIS_STYLE } from '../../utils/constants';
import { formatDate } from '../../utils/helpers';

const AnnouncementsListPage: React.FC = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const {
    loadingAnnouncements,
    announcements,
    listAnnouncementsError,
    totalSize,
    listAnnouncements,
    clearErrors,
    setSelectedAnnouncement,
  } = store.announcementStore;

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const pagedData = announcements?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  useEffect(() => {
    refreshPage();
  }, []);

  const refreshPage = () => {
    setPage(1);
    const req: ListAnnouncementsRequest = {
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    listAnnouncements(req, true);
  };

  useEffect(() => {
    if (listAnnouncementsError) {
      toastService.showError(
        `Failed to fetch announcements: ${listAnnouncementsError}`,
      );
      clearErrors();
    }
  }, [listAnnouncementsError]);

  const fetchNextAnnouncements = async (nextPage: number) => {
    listAnnouncements(
      { pageNumber: nextPage, pageSize: rowsPerPage },
      false /* Refresh */,
    );
  };

  const onTableRowClick = (announcement: Announcement) => {
    navigate(`/announcements/${announcement.id}`);
    setSelectedAnnouncement(announcement);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
          gap: '8px',
          marginTop: '24px',
          marginBottom: '24px',
        }}
      ></Box>

      {loadingAnnouncements ? (
        <Box sx={{ display: 'flex', pt: '60px', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ marginTop: '30px', borderRadius: '10px' }}>
          <TableContainer
            sx={{
              borderStartStartRadius: 'inherit',
              borderStartEndRadius: 'inherit',
            }}
          >
            <Table sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: ACCENT_COLOR_1 }}>
                  <CustomTableCell title='Name'>
                    <CustomTableLabel label='Name' />
                  </CustomTableCell>
                  <CustomTableCell title='Description'>
                    <CustomTableLabel label='Description' />
                  </CustomTableCell>
                  <CustomTableCell title='Type'>
                    <CustomTableLabel label='Type' />
                  </CustomTableCell>
                  <CustomTableCell title='Created Time'>
                    <CustomTableLabel label='Created Time' />
                  </CustomTableCell>
                  <CustomTableCell title='Updated Time'>
                    <CustomTableLabel label='Updated Time' />
                  </CustomTableCell>
                  <CustomTableCell title='Status'>
                    <CustomTableLabel label='Status' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedData.map((announcement, index) => (
                  <StyledTableRow
                    key={announcement.displayName}
                    tabIndex={0}
                    role='tr'
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'white' : '#F9F9F9',
                      cursor: 'pointer',
                      ':hover': { backgroundColor: '#1669F74D' },
                    }}
                    onClick={() => onTableRowClick(announcement)}
                  >
                    <CustomTableCell>
                      <span title={announcement.displayName ?? 'NO DATA'}>
                        {announcement.displayName ?? 'NO DATA'}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell>
                      <CustomTypography
                        typographyType={TypographyType.Label}
                        sx={{
                          ...ELLIPSIS_STYLE,
                        }}
                      >
                        {announcement.description ?? '-'}
                      </CustomTypography>
                    </CustomTableCell>
                    <CustomTableCell ellipsis={false}>
                      <CustomTypography>
                        {announcementTypeToJSON(
                          announcement.type ?? AnnouncementType.UNRECOGNIZED,
                        )}
                      </CustomTypography>
                    </CustomTableCell>
                    <CustomTableCell ellipsis={false}>
                      <CustomTypography>
                        {announcement.createdAt
                          ? formatDate(announcement.createdAt)
                          : '-'}
                      </CustomTypography>
                    </CustomTableCell>
                    <CustomTableCell ellipsis={false}>
                      <CustomTypography>
                        {announcement.updatedAt
                          ? formatDate(announcement.updatedAt)
                          : '-'}
                      </CustomTypography>
                    </CustomTableCell>
                    <CustomTableCell ellipsis={false}>
                      <CustomTypography>
                        {announcement.isActive ? (
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={1}
                          >
                            <VisibilityIcon color='primary' />
                            <Typography variant='body1'>Enabled</Typography>
                          </Stack>
                        ) : (
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={1}
                          >
                            <VisibilityOffIcon />
                            <Typography variant='body1'>Disabled</Typography>
                          </Stack>
                        )}
                      </CustomTypography>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            rowsPerPage={10}
            totalSize={totalSize}
            page={page - 1}
            onNextPage={(newPage: number) => {
              setPage(newPage + 1);
              if (
                announcements.length < totalSize! &&
                announcements.length < (newPage + 1) * rowsPerPage
              ) {
                fetchNextAnnouncements(newPage + 1);
              }
            }}
            onPrevPage={(newPage: number) => {
              setPage(newPage + 1);
            }}
          />
        </Box>
      )}
    </>
  );
});

export default AnnouncementsListPage;
